.modal.modal-center {
  text-align: center;
}

@media screen and (min-width: 768px) { 
  .modal.modal-center:before {
    display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 100%;
  }
}

.modal-dialog.modal-center {
  display: inline-block;
  text-align: left;
  vertical-align: middle; 
}



.geotext{
  text-align: right;
  color: black;
  font-size: 20px;
  font-weight: 400;
  position: absolute;
  z-index: 2;
  margin-top: -7px;
}

.modal-dialog.modal-fullsize {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.modal-content.modal-fullsize {
  height: auto;
  min-height: 100%;
  border-radius: 0;
}


.modal-dialog,
.modal-content {
    /* 80% of window height */
    /*height: 50%;*/
}


/*
.modal-body {
    max-height: calc(100% - 120px);
    overflow-y: scroll;
}

*/
/*
.modal-body {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
}*/